@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-slide button {
  outline: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
